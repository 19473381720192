<template>
  <div id="LiveDetail" v-if="getDetail">
    <div class="header">
      <div class="top">
        <!-- <img @click="toLive" src="../../assets/image/home/index1.png"/> -->
        <!-- <div class="center"  @click="toLive" v-if="Date.parse(new Date())>Date.parse(new Date((getDetail.livetime_start).replace(/-/g, '/'))) && Date.parse(new Date())<Date.parse(new Date((getDetail.livetime_end).replace(/-/g, '/')))">
          <img style="height:0.1rem;margin-right:0.1rem" src="../../assets/image/findOut/ing.png" />点击进入直播间
        </div> -->
         <!-- v-if="Date.parse(new Date())<Date.parse(new Date((getDetail.livetime_start).replace(/-/g, '/')))" -->
        <!-- <div class="center"  @click="$toast('直播未开始')">
          <img style="height:0.1rem;margin-right:0.1rem" src="../../assets/image/findOut/begain.png" />直播还未开始
        </div> -->
        <!-- <div class="bottom" v-if="Date.parse(new Date())<Date.parse(new Date((getDetail.livetime_start).replace(/-/g, '/')))">
          <div v-html="timeOrder"></div>
          <span><span @click="toliveSubScribe">{{getDetail.subscribeStatus ? '已预约':'预约直播'}}</span></span>
        </div> -->
      </div>
      <div class="hotMain">
       
        <p class="second">{{getDetail.title}}</p>
         <div class="first" style="margin-top:0.1rem">
          <!-- <span class="back" v-if="getDetail.type == 2">回放</span>
          <span class="order" v-else-if="Date.parse(new Date())<Date.parse(new Date((getDetail.livetime_start).replace(/-/g, '/')))">预约中</span>
          <span class="active" v-else-if="Date.parse(new Date())>Date.parse(new Date((getDetail.livetime_start).replace(/-/g, '/'))) && Date.parse(new Date())<Date.parse(new Date((getDetail.livetime_end).replace(/-/g, '/')))">直播中</span> -->
          <span>{{getDetail.livetime_start}}</span>
        </div>
        <!-- <div class="forth">
          <div v-if="getDetail.subscribeCount"><img v-for="(i,item) in getDetail.subscribeUserHead" :key="item" :src="Global.changeImg(i.user.headimgurl)" /> 等{{getDetail.subscribeCount}}人已预约</div>
        </div> -->
      </div>
    </div>

    <div class="personalInfo">
      <div >
        <p  class="second">直播内容</p>
        <p  class="third" v-html="getDetail.content"></p>
      </div>
      <!-- <div style="padding-right:0" v-if="series_guest.length">
        <p  class="second">直播嘉宾</p>
        <div class="familiar">
          <div v-for="(i,item) in series_guest" :key="item" @click="toIntroduction(i.user_id)">
            <img :src="Global.changeImg(i.user.headimgurl)" />
            <p>{{i.member_info.name}}</p>
            <p>{{i.member_info.company}}</p>
            <p>{{i.member_info.job}}</p>
          </div>
        </div>
      </div>
      <div  v-if="getDetail.welfare">
        <p  class="second">直播福利</p>
        <p  class="third">{{getDetail.welfare}}</p>
      </div>
      <div style="margin-bottom:0" v-if="liveDoc">
        <p  class="second">直播资料</p>

        <div class="fifth"  @click="toLink(liveDoc.path,liveDoc.id)">
          <img v-if="liveDoc.type == 1" src="../../assets/image/home/doc_type_1.png" />
          <img v-else-if="liveDoc.type == 2" src="../../assets/image/home/doc_type_2.png" />
          <img v-else-if="liveDoc.type == 3" src="../../assets/image/home/doc_type_3.png" />
          <img v-else-if="liveDoc.type == 4" src="../../assets/image/home/doc_type_4.png" />
          <img v-else-if="liveDoc.type == 5" src="../../assets/image/home/doc_type_5.png" />
          <div>
            <p>{{liveDoc.name}}</p>
            <p>{{liveDoc.ext}} {{liveDoc.size}}</p>
          </div>
        </div>
      </div> -->
    </div>

    <div class="fixedBottom" @click="toliveSubScribe" v-if="Date.parse(new Date())<Date.parse(new Date((getDetail.livetime_start).replace(/-/g, '/')))">
      <img src="../../assets/image/findOut/time.png" />
      {{getDetail.subscribeStatus ? '已预约':'预约直播'}}
    </div>
  </div>
</template>
<script>
export default {
  name: 'LiveDetail',
  data(){
    return{
      liveNow:1,
      getDetail:'',
      series_guest:'',
      timeOrder:'',
      timer:'',
      liveDoc:''
    }
  },
  mounted(){
    this.axios({
      method: 'GET',
      url: '/live/getLiveDetail/'+this.$route.query.id,
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      async:false
    }).then((res) => {
      if(res.data.code == 0){
        this.getDetail = res.data.data;
        this.series_guest = this.getDetail.liveGuest;
        this.liveDoc = this.getDetail.liveDoc;
        if(Date.parse(new Date())<Date.parse(new Date((this.getDetail.livetime_start).replace(/-/g, '/')))){
          this.changeTime()
        }
      }
    })
  },
  methods:{
    toLink(link){
      if(this.getDetail.userStatus == 2){
        window.location.href = link
      }else{
        this.$toast('请先购买会员')
        var _this = this;
        setTimeout(function(){
          _this.$router.push('/MemberProduct')
        },2000)
      }
      // MemberProduct
    },
    toLive(){
      if(Date.parse(new Date())<Date.parse(new Date((this.getDetail.livetime_start).replace(/-/g, '/')))){
        this.$toast('直播未开始')
      }else{
        window.location.href = this.getDetail.address
      }
    },
    // 更改直播时间
    changeTime(){
      var _this = this;
      _this.timer =  setInterval(function(){
        var len =  (Date.parse(new Date((_this.getDetail.livetime_start).replace(/-/g, '/'))) - Date.parse(new Date()))/1000;
        var day = Math.floor(len/(3600*24)); //对一天取整
        var hs = len%(24*3600); //对一天取余
        var h = Math.floor(hs/3600)  //对小时取整
        var m = hs%3600; //对小时取余
        var d = Math.floor(m/60);  //对分钟取整
        var s = m%60 //对分钟取余
        if(!day&&!h&&!d&&!s){
          window.location.reload()
        }
        _this.timeOrder = `距离开播还有：<span>`+day+`</span>天<span>`+h+`</span>时<span>`+d+`</span>分<span>`+s+`</span>秒`
      }, 1000);
    },
    toliveSubScribe(){
      if(!this.getDetail.subscribeStatus){
        this.axios({
          method: 'POST',
          url: '/live/liveSubScribe',
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
          data:{
            liveId:this.$route.query.id
          }
        }).then((res) => {
          if(res.data.code == 0){
            this.$toast('预约成功')
          }
        })
      }
    },
    toIntroduction(id){
      this.$router.push({
        path:'/Introduction?id='+id
      })
    },
  },
  beforeDestroy(){
    var _this;
    clearInterval(_this.timer)
  }
}
</script>

<style lang="less" scoped>
#LiveDetail{
  padding-bottom: 0.3rem;
  .fixedBottom{
    left: 0;
    right: 0;
    margin: auto;
    position: fixed;
    bottom: 0.4rem;
    width: 2.09rem;
    height: 0.42rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #F6F7FD;
    background: #3890FF;
    border-radius: 0.21rem;
    >img{
      width: 0.16rem;
      margin-right: 0.06rem;
    }
  }
  .familiar{
    overflow-x: scroll;
    padding-top: 0.15rem;
    margin-bottom: 0.1rem;
    white-space: nowrap;
    >div{
      width: 1.29rem;
      border-radius: 0.05rem;
      margin-right: 0.15rem;
      text-align: center;
      display: inline-block;
      background:#3D404F;
      >img{
        display: inline-block;
        width: 0.54rem;
        margin: 0.1rem 0 0.09rem;
        border-radius: 50%;
      }
      >p:nth-child(2){
        color: #FFFFFF;
        font-size: 0.14rem;
      }
      >p:nth-child(3){
        color: #9091A3;
        font-size: 0.12rem;
        margin: 0.03rem 0 0.02rem;
      }
      >p:nth-child(4){
        color: #9091A3;
        font-size: 0.12rem;
        margin: 0.03rem 0 0.1rem;
      }
    }
  }
  .personalInfo{
    >div{
      padding: 0.15rem 0.15rem 0.1rem;
      margin-bottom: 0.11rem;
      background: #23252E;
      .third{
        color: #989AB1;
        font-size: 0.13rem;
        line-height: 0.17rem;
        padding: 0.15rem 0 0.1rem;
       
      }
      .second{
        color: #F6F7FD;
        font-size: 0.15rem;
      }
      .fifth{
        background: #3D404F;
        border-radius: 0.05rem;
        padding: 0.11rem 0.15rem;
        display: flex;
        justify-content: flex-start;
        margin: 0.15rem 0 0.12rem 0rem;
        align-items: center;
        >div{
          >p:last-child{
            color: #989AB1;
            font-size: 0.12rem;
          }
          >p:first-child{
            color: #F6F7FD;
            font-size: 0.14rem;
            margin-bottom: 0.05rem;
          }
        }
        >img{
          width: 0.3rem;
          margin-right: 0.16rem;
        }
      }
    }
  }
  
  .header{
    background: #23252E;
    margin-bottom: 0.11rem;
    padding: 0 0.15rem 0.2rem;
    overflow: hidden;
    >.top{
      position: relative;
      .center{
        left: 0;
        right: 0;
        top: 0;
        margin: auto;
        position:absolute;
        font-size: 0.12rem;
        bottom: 0;
        width: 1.35rem;
        height: 0.27rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #F6F7FD;
        background: #23252Ecf;
        border-radius: 0.135rem;
      }
      .bottom{
        >span{
          width: 0.56rem;
          height: 0.18rem;
          background:#FFC192;
          border-radius: 0.09rem;
          color: #FFFFFF;
          text-align: center;
          line-height: 0.18rem;
          >span{
            transform: scale(0.8);
            display: block;
            font-size: 0.12rem;
          }
        }
        >div{
          color: #FFFFFF;
          font-size: 0.12rem;
          >span{
            font-size: 0.13rem;
            font-weight: bold;
          }
        }
        position: absolute;
        height: 0.27rem;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        bottom: 0;
        padding: 0 0.2rem;
        background: url(../../assets/image/findOut/live.png) no-repeat;
        background-size: 100% 100%;
      }
      >img{
        width: 100%;
        margin: 0.15rem 0;
        border-bottom-left-radius: 0.05rem;
        border-bottom-right-radius: 0.05rem;
      }
    }
  }
   .hotMain{
      .forth{
        >div:first-child{
          color: #989AB1;
          display: flex;
          align-items: center;
          font-size: 0.12rem;
          >img{
            width: 0.14rem;
            border-radius: 50%;
          }
          >img:nth-child(n+2){
            margin-left: -0.03rem;
          }
          >img:last-child{
            margin-right: 0.05rem;
          }
        }
      }
 
      .second{
        color: #F6F7FD;
        font-size: 0.15rem;
        margin: 0.1rem 0;
      }
      .first{
        color: #727387;
        font-size: 0.12rem;
        >span{
          margin: 0 0.07rem 0 0;
          color: #F6F7FD;
          padding: 0.01rem 0.04rem;
          border-radius: 0.03rem;
        }
        .active{
          background: linear-gradient(90deg, #FF636F 0%, #FE5E67 50%, #FC7277 100%);
         
        }
        .order{
          background: linear-gradient(90deg, #FF7E4E 0%, #FF9544 100%);
        }
        .back{
          background: linear-gradient(90deg, #3C94FF 0%, #4CADFF 100%);
        }
      }
      >div{
        display: flex;
        align-items: center;
      }
  }
    
}
</style>